import { ContestParticipant, UserInPeriodLeaderboard } from 'models';

const checkMeIsEmpty = (me: ContestParticipant | UserInPeriodLeaderboard | undefined) => {
  if (!me) return true;

  const emptyObject = {
    contest: {
      league: null,
      status: null,
      participantsCount: null,
      startsAt: null,
      locksAt: null,
      image: null,
    },
    user: {
      username: '',
      firstName: '',
      lastName: '',
      bio: '',
      avatarUrl: '',
      thumbnailUrl: '',
    },
    status: null,
    place: null,
    nakedScorePoints: null,
    totalScorePoints: null,
    nakedAttributePoints: null,
    totalAttributePoints: null,
    totalPoints: null,
    booster: {
      league: '',
      rarity: '',
      tier: '',
      name: '',
      description: '',
      image: null,
      icon: null,
      backgroundColor: '',
      textColor: '',
      isGlowing: false,
      pointsMultiplier: null,
      attribute: '',
      attributeDisplay: '',
      attributeMultiplier: null,
      usedAt: null,
      updatedAt: null,
    },
  };

  function isEqual(obj1: any, obj2: any): boolean {
    if (typeof obj1 !== typeof obj2) {
      return false;
    }
    if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
      return obj1 === obj2;
    }
    if (Array.isArray(obj1) !== Array.isArray(obj2)) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  return isEqual(me, emptyObject);
};

export default checkMeIsEmpty;
