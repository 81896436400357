import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Grid, Img, Text } from '@chakra-ui/react';
import routes from 'routes';

import Spinner from 'components/Spinner';

type GuestHomeProps = {
  custonOnClick?: () => void;
};

const GuestHome = ({ custonOnClick }: GuestHomeProps) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const handleVibrate = () => {
    ReactGA.event({
      category: 'GuestHome',
      action: 'click',
      label: 'Join button',
    });

    if (custonOnClick) {
      custonOnClick();
    } else {
      navigate(routes.signIn);
    }
  };

  return (
    <Grid
      w="100%"
      h="100%"
      gridTemplateRows="auto 1fr auto"
      pb="20px"
      px="24px"
      opacity={loaded ? 1 : 0}
      transition="opacity 0.2s ease-in-out"
    >
      <Box pos="relative">
        <Spinner
          color="#FFD700"
          p="20px"
          pos="absolute"
          width="100vw"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
        <Img
          display="block"
          pos="relative"
          src="home.webp"
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </Box>
      <Box textAlign="center" pb="24px" maxW="275px" mx="auto">
        <Text variant="text">start bench cut</Text>
        <Text variant="title" mt="4px">
          a non-boring fantasy sports game
        </Text>
        <Text variant="hint" mt="12px">
          spice up the season · collect stuff · compete with friends · enter raffles
        </Text>
      </Box>
      <Flex justify="center">
        <Button onClick={handleVibrate} data-cy="join-button">
          join the game
        </Button>
      </Flex>
    </Grid>
  );
};

export default GuestHome;
