import { Box, Fade } from '@chakra-ui/react';
import { Contest, ContestParticipant } from 'models';

import LeaderboardSkeleton from 'components/leaderboard/LeaderboardSkeleton';

import ContestHistoryItem from './ContestHistoryItem';
import EmptyContestHistory from './EmptyContestHistory';

interface ContestHistoryListProps {
  participations: ContestParticipant[];
  isLoading?: boolean;
}

const ContestHistoryList: React.FC<ContestHistoryListProps> = ({ participations, isLoading }) => {
  return (
    <Box flex="1" alignContent="center">
      {isLoading && (
        <Box p="0px 12px">
          <LeaderboardSkeleton />
        </Box>
      )}
      <Fade in>
        {participations.map((participation, index) => (
          <ContestHistoryItem key={`${participation.id}-${index}`} participation={participation} />
        ))}
        {participations.length === 0 && !isLoading && <EmptyContestHistory />}
      </Fade>
    </Box>
  );
};

export default ContestHistoryList;
