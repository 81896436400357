import { Box, Flex } from '@chakra-ui/react';
import { getOrdinalSuffix } from 'utils/getOriginalSuffix';

const ParticipantPosition = ({ position }: { position: number }) => {
  return (
    <Flex opacity={position > 3 ? 0.5 : 1} alignItems="flex-end">
      <Box fontSize="20px" fontWeight="800" lineHeight="36px">
        {position}
      </Box>
      {/* <Box fontSize="14px" fontWeight="700" lineHeight="14px">
        {getOrdinalSuffix(position)}
      </Box> */}
    </Flex>
  );
};

export default ParticipantPosition;
