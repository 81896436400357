import { update } from 'cypress/types/lodash';
import { type User } from 'models';

import { api } from './api';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: '/user/',
      }),
    }),
    updateUser: builder.mutation<User, { username?: string; bio?: string; avatar?: string | null }>({
      query: ({ username, bio, avatar }) => {
        return {
          url: `/user/`,
          method: 'POST',
          body: { username, bio },
        };
      },
    }),
    updateUserAvatar: builder.mutation<User, { avatar: string | null }>({
      query: ({ avatar }) => {
        return {
          url: `/user/`,
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          body: { avatar },
        };
      },
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery, useUpdateUserMutation, useUpdateUserAvatarMutation } = authApi;
