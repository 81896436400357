import { FC } from 'react';

import { Box, Fade, Flex, Grid, keyframes } from '@chakra-ui/react';

const shineAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

interface Props {
  height?: string;
  count?: number;
}

const LeaderboardSkeleton: FC<Props> = ({ height = '130px', count = 6 }) => {
  return (
    <Fade in={true} style={{ flex: 1 }}>
      <Flex flexDirection="column" flex="1" gap="2.5" mt="10px">
        {Array.from({ length: count }).map((p, index) => (
          <Box
            key={`lbh-skeleton-${index}`}
            w="100%"
            h={height}
            borderRadius="4px"
            transition="opacity 0.2s linear"
            backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)"
            backgroundSize="400px 100%"
            animation={`${shineAnimation} 1.5s infinite linear`}
          />
        ))}
      </Flex>
    </Fade>
  );
};

export default LeaderboardSkeleton;
