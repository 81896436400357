import { Box, Fade, Flex, Grid } from '@chakra-ui/react';

import { ReactComponent as TrophyIcon } from 'icons/trophy.svg';

const EmptyContestHistory: React.FC = () => {
  return (
    <Grid pos="relative" pt="0px" pb="20px" gridTemplateRows="1fr auto" flex="3">
      <Flex flexDir="column" align="center" justify="center" gap="16px" px="12px">
        <Fade in={true}>
          <Box as={TrophyIcon} mx="auto" mb="8px" />
          <Box fontSize="14px" fontWeight="600" lineHeight="16.8px" opacity="0.8" textAlign="center">
            Squads and ranks
            <br />
            are gonna pop up here
          </Box>
        </Fade>
      </Flex>
    </Grid>
  );
};

export default EmptyContestHistory;
