import { Link } from 'react-router-dom';

import { Box, Flex, Grid } from '@chakra-ui/react';
import routes from 'routes';

const Header = () => {
  return (
    <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" py="8px" px="12px" w="100%">
      <Box fontSize="14px" fontWeight="500" cursor="pointer"></Box>

      <Box fontSize="14px" fontWeight="500" cursor="pointer"></Box>

      <Box as={Link} to={routes.home} fontSize="14px" fontWeight="500">
        close
      </Box>
    </Grid>
  );
};

export default Header;
