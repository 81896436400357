import { useEffect, useRef, useState } from 'react';

import { Box, Flex, Spinner } from '@chakra-ui/react';
import { ContestParticipant, User } from 'models';

import { useLazyGetParticipantHistoryQuery } from 'api/contest';

import { useAppSelector } from 'hooks/app';

import ContestHistoryList from './ContestHistoryList';

interface Props {
  user?: User;
}

const ContestHistory: React.FC<Props> = ({ user }) => {
  const currentUser = useAppSelector((store) => store.auth.user);
  const loadRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [participations, setParticipations] = useState<ContestParticipant[]>([]);
  const [trigger, { data, isFetching, isLoading }] = useLazyGetParticipantHistoryQuery();

  useEffect(() => {
    if (!isFetching) {
      trigger({ offset: page * 5, limit: 5, userId: user ? user.id : currentUser ? currentUser.id : '' });
    }
  }, [page, trigger]);

  useEffect(() => {
    if (data) {
      setParticipations((prevParticipations) => [...prevParticipations, ...data.results]);
    }
  }, [data]);

  useEffect(() => {
    let observer: IntersectionObserver;
    if (loadRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1.0 }
      );

      observer.observe(loadRef.current);
    }

    return () => {
      if (loadRef.current) {
        observer?.unobserve(loadRef.current);
      }
    };
  }, [isFetching, loadRef]);

  return (
    <Flex flex="1" flexDirection="column">
      <ContestHistoryList isLoading={isLoading} participations={participations} />

      {data?.next && (
        <Flex w="100%" justifyContent="center" p="60px 0">
          {!isFetching && <Box ref={loadRef} />}
          {isFetching && <Spinner size="sm" color="rgba(255,255,255,0.4)" />}
        </Flex>
      )}
    </Flex>
  );
};

export default ContestHistory;
