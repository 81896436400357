import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';

export type Period = 'daily' | 'weekly' | 'monthly';

type PeriodOption = {
  label: string;
  value: Period;
};

const periodOptions: PeriodOption[] = [
  { label: 'daily', value: 'daily' },
  { label: 'weekly', value: 'weekly' },
  { label: 'monthly', value: 'monthly' },
];

interface LeaderboardPeriodControlsProps {
  onChange?: (value: Period) => void;
  innitialActivePeriod?: Period;
}

const LeaderboardPeriodControls = ({ onChange, innitialActivePeriod }: LeaderboardPeriodControlsProps) => {
  const [activePeriod, setActivePeriod] = useState(innitialActivePeriod);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsFriends = searchParams.get('friends');

  const handlePeriodChange = (value: Period) => {
    setActivePeriod(value);
    if (value !== 'daily') {
      navigate(`/leaderboard/${value}${searchParamsFriends !== null ? `/?friends=${searchParamsFriends}` : ''}`);
    } else if (value === 'daily') {
      navigate(`/leaderboard${searchParamsFriends !== null ? `/?friends=${searchParamsFriends}` : ''}`);
    }
  };

  return (
    <Flex
      mx="-12px"
      px="12px"
      borderBottom="1px solid rgba(255,255,255,0.4)"
      pos="sticky"
      top="0"
      zIndex="10"
      bg="#242424"
    >
      {periodOptions.map((option: PeriodOption) => (
        <Box
          key={option.value}
          flex="1"
          p="8px 0 16px"
          borderBottom={activePeriod === option.value ? '1px solid white' : '1px solid rgba(255,255,255,0.4)'}
          color={activePeriod === option.value ? 'white' : 'rgba(255,255,255,0.4)'}
          textAlign="center"
          fontSize="16px"
          fontWeight="700"
          cursor="pointer"
          mb="-1px"
          onClick={() => handlePeriodChange(option.value)}
          textDecoration="none !important"
          transition="all 0.2s ease-in-out"
          _active={{ color: 'rgba(255,255,255,0.5)', borderColor: 'rgba(255,255,255,0.5)' }}
        >
          {option.label}
        </Box>
      ))}
    </Flex>
  );
};

export default LeaderboardPeriodControls;
