import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Link, Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Flex, Grid } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import routes from 'routes';
import { checkLastVisitFromApp } from 'utils/checkLastVisitFromApp';

import { useGetUserQuery } from 'api/auth';

import { Loader } from 'components/Loader';
import FeedbackChat from 'components/home/FeedbackChat';
import GuestHome from 'components/home/GuestHome';
import Header from 'components/layout/Header';
import Protected from 'components/layout/Protected';
import AllBoostersPage from 'components/pages/AllBoostersPage';
import BoostersPage from 'components/pages/BoostersPage';
import BuilderPage from 'components/pages/BuilderPage/BuilderPage';
import HomePage from 'components/pages/HomePage';
import InvitePage from 'components/pages/InvitePage';
import LeaderboardPage from 'components/pages/LeaderboardPage';
import LeaderboardPeriodPage from 'components/pages/LeaderboardPeriodPage';
import LogoutPage from 'components/pages/LogoutPage';
import ProfileEditorPage from 'components/pages/ProfileEditorPage/ProfileEditorPage';
import ProfilePage from 'components/pages/ProfilePage/ProfilePage';
import SignInPage from 'components/pages/SignInPage';
import SquadPage from 'components/pages/SquadPage';
import StreakPromoPage from 'components/pages/StreakPromoPage/StreakPromoPage';

import { useAppDispatch, useAppSelector } from 'hooks/app';
import useContest from 'hooks/useContest';

import { setIsAuthicated, setUser } from 'store/slices/auth';

import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

function App() {
  const [token, setToken] = useState(Cookies.get('sbc_access_token'));
  const { isLoading, data, error: userError, refetch } = useGetUserQuery(undefined, { skip: !token });
  const { isLoading: isContestLoading } = useContest();
  const dispatch = useAppDispatch();
  const isUserLoading = useAppSelector((state) => state.auth.isLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const [inited, setInited] = useState(false);
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');

  useEffect(() => {
    const bioUpdateStatus = location.state?.bioUpdateStatus;
    const usernameUpdateStatus = location.state?.usernameUpdateStatus;
    if (bioUpdateStatus?.isSuccess || usernameUpdateStatus?.isSuccess) {
      refetch();
    }
  }, [location.state]);

  useEffect(() => {
    if (!TRACKING_ID) return;
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title });
  }, [location.pathname]);

  useEffect(() => {
    if (!Cookies.get('sbc_access_token')) {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      if (token) {
        Cookies.set('sbc_access_token', token, { secure: true, expires: 60 * 60 * 24 * 7 });
        setToken(token);
        navigate(location.pathname, { replace: true });
        return;
      }
    }
    if (userError) {
      Sentry.captureException(userError);
    }
    if (isLoading) return;
    dispatch(setIsAuthicated(!!data));
    dispatch(setUser(data));
    setInited(true);
  }, [isLoading, data, userError]);

  if (isUserLoading || isContestLoading || !inited) return <Loader />;

  if (from === 'app' && checkLastVisitFromApp()) {
    return (
      <Flex h="100vh" align="center" justify="center" direction="column" gap="0px">
        <Flex mt="8px" alignItems="center" justify="center" overflow="hidden" h="48px" zIndex="100" pos="relative">
          <Box as={Link} to={routes.home}>
            <LogoIcon />
          </Box>
        </Flex>
        <GuestHome
          custonOnClick={() => {
            localStorage.setItem('sbc_landing_for_app', new Date().toISOString());
            navigate(routes.home);
          }}
        />
      </Flex>
    );
  }

  return (
    <Grid
      gridTemplateRows="auto 1fr"
      id="app"
      pt="8px"
      userSelect="none"
      style={
        location.pathname === routes.squad
          ? {
              height: 'auto',
            }
          : undefined
      }
    >
      <Header />
      <Routes>
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.signIn} element={<SignInPage />} />
        <Route element={<Protected />}>
          <Route path={routes.builder} element={<BuilderPage />} />
          <Route path={routes.boosters} element={<BoostersPage />} />
          <Route path={routes.allBoosters} element={<AllBoostersPage />} />
          <Route path={routes.squad} element={<SquadPage />} />
          <Route path={routes.logout} element={<LogoutPage />} />
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={`${routes.profile}/:type/`} element={<ProfileEditorPage />} />
          <Route path={routes.streakPromo} element={<StreakPromoPage />} />
        </Route>
        <Route path={routes.leaderboard} element={<LeaderboardPage />} />
        <Route path={`${routes.leaderboard}/:period/`} element={<LeaderboardPeriodPage />} />
        <Route path={`${routes.invite}/:code/`} element={<InvitePage />} />
        <Route path="*" element={<Navigate to={routes.home} replace />} />
      </Routes>
      {data && <FeedbackChat user={data} isAvailable={location.pathname === '/'} />}
    </Grid>
  );
}

export default App;
