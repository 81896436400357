import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  h: '54px',
  px: '16px',
  fontWeight: '500',
  fontSize: '16px',
  border: '1px solid #FFFFFF33',
  borderRadius: '16px',
  color: '#FFFFFF',
  _placeholder: {
    color: '#FFFFFF99',
  },
  _hover: {
    outline: 'none',
  },
  _focusVisible: {
    outline: 'none',
    borderColor: '#FFFFFF',
    boxShadow: 'none',
  },
  _focus: {
    outline: 'none',
    borderColor: '#FFFFFF',
    boxShadow: 'none',
  },
});

const TextareaConfig = defineStyleConfig({
  baseStyle: {
    field: {
      h: '54px',
      px: '16px',
      fontWeight: '400',
      fontSize: '16px',
      border: '1px solid #FFFFFF33',
      borderRadius: '16px',
      color: '#FFFFFF',
      _placeholder: {
        color: '#FFFFFF99',
      },
      _hover: {
        outline: 'none',
      },
      _focusVisible: {
        outline: 'none',
        borderColor: '#FFFFFF',
        boxShadow: 'none',
      },
      _focus: {
        outline: 'none',
        borderColor: '#FFFFFF',
        boxShadow: 'none',
      },
    },
  },
  variants: { outline },
  defaultProps: {},
});

export default TextareaConfig;
