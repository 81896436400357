import dayjs from 'dayjs';
import { Contest } from 'models';
import { formatDuration } from 'utils/formatDuration';

export function getEventStatusString(contest: Contest) {
  const now = dayjs().tz('America/New_York');
  const locksAtNewYork = dayjs(contest.status === 'scheduled' ? contest.startsAt : contest.locksAt).tz(
    'America/New_York'
  );
  const locksDiff = locksAtNewYork.diff(now);
  const durationUntilLocks = dayjs.duration(locksDiff);

  if (contest.status === 'completed') {
    return 'results are in';
  } else if (contest.status === 'locked') {
    return 'results are pending';
  } else if (contest.status === 'active') {
    // return `locks in ${formatDuration(durationUntilLocks)}`;
    return parseInt(formatDuration(durationUntilLocks)) < 0
      ? 'locks in 0'
      : `locks in ${formatDuration(durationUntilLocks)}`;
  } else if (contest.status === 'scheduled') {
    const startTime = formatDuration(durationUntilLocks);
    return parseInt(startTime) < 0 ? 'opening soon' : `opens in ${startTime}`;
  }

  return 'unknown status';
}
