import { Box } from '@chakra-ui/react';
import { Booster } from 'models';

import BoosterCard from 'components/booster/BoosterCard';

interface Props {
  slide: Booster;
  minCardHeight?: string | number;
}

const Slide = ({ slide, minCardHeight = '255px' }: Props) => {
  return <BoosterCard minCardHeight={minCardHeight} booster={slide} />;
};

export default Slide;
