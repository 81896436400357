import { Fade, Flex } from '@chakra-ui/react';

import ContestHistory from 'components/profile/ContestHistory/ContestHistory';
import ProfileHeader from 'components/profile/ProfileHeader/ProfileHeader';
import UserInfo from 'components/profile/UserInfo/UserInfo';

const ProfilePage = () => {
  return (
    <Flex direction="column">
      <ProfileHeader />
      <Fade in>
        <Flex
          p="64px 0 0"
          direction="column"
          backgroundImage="/sbc-bg.png"
          backgroundSize="auto 100%"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        >
          <UserInfo />
        </Flex>
      </Fade>
      <ContestHistory />
    </Flex>
  );
};

export default ProfilePage;
