import { LeaderboardStat, LeaderboardStatPeriod } from 'models';

import { Period } from 'components/leaderboard/LeaderboardPeriodControls';

import { api } from './api';

const contestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderboard: builder.query<LeaderboardStat, { contestId: number; friends?: boolean }>({
      query: ({ contestId, friends }) => {
        return {
          url: `contest/leaderboard/`,
          method: 'GET',
          params: {
            ...(contestId !== null && { contestId }),
            ...(friends && { friends }),
          },
        };
      },
    }),
    getLeaderboardByPeriod: builder.query<
      LeaderboardStatPeriod,
      { leaderboardId: number | null; period: Period; friends?: boolean }
    >({
      query: ({ leaderboardId, period, friends }) => {
        return {
          url: `contest/leaderboard/${period}/`,
          params: {
            ...(leaderboardId !== null && { leaderboardId }),
            ...(friends && { friends }),
          },
        };
      },
    }),
  }),
});

export const { useGetLeaderboardQuery, useGetLeaderboardByPeriodQuery } = contestApi;
