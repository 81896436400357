import { FC, useEffect, useState } from 'react';

import { Box, Fade, Flex, keyframes } from '@chakra-ui/react';
import { Booster } from 'models';
import { preloadImage } from 'utils/preloadImage';

import { Loader } from 'components/Loader';
import { SpinnerProps } from 'components/pages/BuilderPage/types';
import BoosterCards from 'components/pages/BuilderPage/ui/BoosterCards';
import BoosterPack from 'components/pages/BuilderPage/ui/BoosterPack';

import useSpinner from 'hooks/useSpinner';

import SpinnerLoader from './SpinnerLoader';

// const firstSpinImage =
//   'https://clutchpoints-store-media.s3.amazonaws.com/SBC+media/Starter+pack/spin-cover_pack-nba.webp';
// const cardCover = 'https://clutchpoints-store-media.s3.amazonaws.com/SBC+media/Starter+pack/spin-cover_card-nba.webp';
const cardCover = './card-back.webp';
const firstSpinImage =
  'https://clutchpoints-store-media.s3.us-east-1.amazonaws.com/SBC+media/booster-icon-placeholders/sbc-welcome-pack.webp';
const dailySpinImage =
  'https://clutchpoints-store-media.s3.us-east-1.amazonaws.com/SBC+media/booster-icon-placeholders/sbc-daily-pack.webp';
const streakSpinImage =
  'https://clutchpoints-store-media.s3.us-east-1.amazonaws.com/SBC+media/booster-icon-placeholders/sbc-streak-pack.webp';

preloadImage(firstSpinImage);
preloadImage(dailySpinImage);
preloadImage(streakSpinImage);
preloadImage(cardCover);

const Spinner: FC<SpinnerProps> = ({ dailySpinStatus, onClose }) => {
  const [isAvailableToGetBooster, setIsAvailableToGetBooster] = useState(false);
  const [showBoosters, setShowBoosters] = useState(false);
  const [boosters, setBoosters] = useState<Booster[] | null>(null);
  const { data, isLoading, isFetching } = useSpinner(isAvailableToGetBooster);

  useEffect(() => {
    if (data) {
      setBoosters([...data.boosters]);
    }
  }, [data]);

  const image = () => {
    switch (dailySpinStatus?.type) {
      case 'first_spin':
        return firstSpinImage;
      case 'daily_spin':
        return dailySpinImage;
      case 'streak_spin':
        return streakSpinImage;
      default:
        return dailySpinImage;
    }
  };

  return (
    <Box px="12px" pt="8px" position="fixed" top="0" left="0" w="100%" h="100%" zIndex="99" background="#242424">
      <Flex alignItems="center" justifyContent="center" flexDirection="column" h="100%" gap="15px">
        <>
          <SpinnerLoader />
          <BoosterPack
            onPackClick={() => setIsAvailableToGetBooster(true)}
            onPackCut={() => setShowBoosters(true)}
            // isFirstSpin={!hadFirstSpin}
            image={image()}
            cardCover={cardCover}
            boosters={boosters}
          />
        </>

        {showBoosters && <BoosterCards onClose={onClose} boosters={boosters} cardCover={cardCover} />}
      </Flex>
    </Box>
  );
};

export default Spinner;
