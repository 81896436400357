export const checkLastVisitFromApp = () => {
  const lastVisit = localStorage.getItem('sbc_landing_for_app');
  if (lastVisit) {
    const lastVisitDate = new Date(lastVisit);
    const diffTime = Math.abs(new Date().getTime() - lastVisitDate.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays >= 365;
  }
  return true;
};
