import { FC, RefObject, forwardRef } from 'react';

import { Box, GridItem, GridItemProps, Img, Text } from '@chakra-ui/react';
import { Booster } from 'models';

type BoosterCardProps = {
  booster: Booster;
  type?: 'spin' | 'default';
  cardWidth?: string | number;
  minCardHeight?: string | number;
} & GridItemProps;

const BoosterCard = forwardRef<HTMLDivElement, BoosterCardProps>(
  ({ booster, onClick, type = 'default', minCardHeight = '255px', ...props }, ref) => {
    return (
      <GridItem ref={ref} {...props}>
        <Box
          pos="relative"
          bgImage={`url(${booster.image})`}
          bgSize="cover"
          bgPos="center center"
          width={'100%'}
          minHeight={minCardHeight}
          aspectRatio={'1/1.5'}
          bgColor={booster.backgroundColor ?? 'white'}
          boxShadow="0px 6px 50px 0px #000000CC"
          borderRadius="16px"
          transition="opacity 0.2s linear"
          onClick={onClick}
          overflow="hidden"
        >
          <Text
            color={booster.textColor ?? undefined}
            variant="boosterName"
            pos="absolute"
            top="12px"
            left="12px"
            right="12px"
          >
            {`${booster.rarity} ${booster.attributeDisplay}`}
          </Text>
          <Text
            variant="boosterMultiplier"
            pos="absolute"
            bottom="16px"
            left="12px"
            right="12px"
            zIndex="100"
            color={booster.textColor ?? undefined}
          >
            <Text
              lineHeight="1.2"
              p="2px 6px"
              fontSize="14px"
              fontWeight="700"
              bgColor={booster.backgroundColor ?? undefined}
              borderRadius="4px"
              as="span"
            >
              {booster.pointsMultiplier} x PTS
            </Text>

            <br />
            <Text
              lineHeight="1.2"
              p="2px 6px"
              fontSize="14px"
              fontWeight="700"
              bgColor={booster.backgroundColor ?? undefined}
              borderRadius="4px"
              as="span"
            >
              {booster.attributeMultiplier} x {booster.attributeDisplay?.toUpperCase()}
            </Text>
            <br />
          </Text>
          {booster.icon && (
            <Img
              src={booster.icon}
              pos="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              objectFit="contain"
              objectPosition="center"
            />
          )}
        </Box>
      </GridItem>
    );
  }
);

BoosterCard.displayName = 'BoosterCard';

export default BoosterCard;
