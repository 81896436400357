import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Flex, Grid, Img, Text } from '@chakra-ui/react';
import { User } from 'models';
import routes from 'routes';
import { checkLastVisitFromApp } from 'utils/checkLastVisitFromApp';

import FeedbackChat from 'components/home/FeedbackChat';

import { ReactComponent as LeftArrowIcon } from 'icons/back-arr.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

interface Props {
  onClose?: () => void;
  user?: User;
}

const Menu: React.FC<Props> = ({ onClose, user }) => {
  const [showChat, setShowChat] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const menuItems = [
    {
      id: 'name',
      title: 'name',
      content: user?.username,
      onClick: () => {
        navigate(`${routes.editName}/`, { state: { from: location.pathname } });
      },
    },
    // {
    //   id: 'profile-pic',
    //   title: 'profile pic',
    //   content: user?.avatarUrl,
    //   onClick: () => {
    //     navigate(`${routes.editAvatar}/`, { state: { from: location.pathname } });
    //   },
    // },
    {
      id: 'bio-and-socials',
      title: 'bio and socials',
      content: null,
      onClick: () => {
        navigate(`${routes.editBio}/`, { state: { from: location.pathname } });
      },
    },
    {
      id: 'chat',
      title: 'chat with us',
      content: null,
      onClick: () => {
        setShowChat(true);
        if ((window as any).fcWidget && showChat) {
          (window as any).fcWidget.open();
        }
      },
    },
    {
      id: 'logout',
      title: 'logout',
      content: null,
      onClick: () => {
        navigate('/logout');
      },
    },
  ];

  const onChatCloseHandler = () => {
    setShowChat(false);
  };

  return (
    <>
      {showChat && user && <FeedbackChat user={user} openOnMount onClose={onChatCloseHandler} />}
      <Flex
        flexDirection="column"
        gap="0px"
        w="100%"
        h="100vh"
        flex="1"
        pos="fixed"
        top="0"
        backgroundColor="#242424"
        zIndex="99"
        maxW="500px"
        margin="0 auto"
      >
        <Grid
          gridTemplateColumns="48px 1fr 48px"
          gap="8px"
          py="10px"
          px="12px"
          zIndex="1"
          w="100%"
          maxW="500px"
          margin="0 auto"
          transition="all 0.2s ease-in-out"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              onClick={onCloseHandler}
              height="48px"
              width="48px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
              borderRadius="12px"
              transition="all 0.2s ease-in-out"
              _active={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }}
            >
              <Box as={LeftArrowIcon} />
            </Box>
          </Box>
          <Flex textAlign="center" justifyContent="center" alignItems="center">
            <Text lineHeight="1.2" fontSize="16px" align="center">
              settings
            </Text>
          </Flex>
          <Flex
            fontSize="20px"
            lineHeight="1"
            borderRadius="12px"
            transition="all 0.2s ease-in-out"
            height="48px"
            width="48px"
            alignItems="center"
            justifyContent="center"
            gap="3px"
          ></Flex>
        </Grid>
        <Flex flexDirection="column" w="100%">
          {menuItems.map((item, index) => (
            <Flex
              display={item.id === 'logout' && localStorage.getItem('sbc_landing_for_app') ? 'none' : 'flex'}
              key={`menu-item-${index}`}
              flexDirection="row"
              alignItems="center"
              gap="8px"
              p="16px"
              borderTop="1px solid #3a3a3a"
              borderBottom="1px solid #3a3a3a"
              mb="-1px"
              w="100%"
              className="group"
              onClick={item.onClick}
              cursor="pointer"
            >
              <Box
                fontSize="18px"
                fontWeight="700"
                lineHeight="1.2"
                flex="1"
                color={item.id !== 'logout' ? 'rgba(255, 255, 255, 1)' : 'rgba(248, 60, 60, 1)'}
              >
                {item.title}
              </Box>
              <Flex gap="15px" alignItems="center">
                {item.id === 'profile-pic' && item.content ? (
                  <Img w="28px" h="28px" objectFit="cover" borderRadius="10px" src={item.content} alt={item.title} />
                ) : (
                  item.content && (
                    <Box opacity="0.5" fontSize="15px" fontWeight="500" lineHeight="1.2">
                      {item.content}
                    </Box>
                  )
                )}
                {item.id !== 'logout' && (
                  <Box opacity="0.5" transition="all .2s ease" _groupActive={{ opacity: 1 }} as={RightArrowIcon}></Box>
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default Menu;
