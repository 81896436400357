import { Link } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { User } from 'models';
import routes from 'routes';

interface Props {
  user: User;
}

const Avatar: React.FC<Props> = ({ user }) => {
  return (
    // <Box as={Link} to={routes.editAvatar} state={{ from: location.pathname }}>
    <Box>
      {user.avatarUrl ? (
        <Box
          w="72px"
          h="72px"
          borderRadius="24px"
          fontSize="16px"
          fontWeight="800"
          color="#FFFFFFCC"
          flexShrink="0"
          textTransform="uppercase"
          backgroundImage={user.avatarUrl}
          backgroundPosition="center center"
          backgroundSize="cover"
          pos="relative"
          overflow="hidden"
        />
      ) : (
        <Box
          w="72px"
          h="72px"
          borderRadius="24px"
          background="#3a3a3a"
          fontWeight="800"
          flexShrink="0"
          textTransform="uppercase"
          backgroundPosition="center center"
          backgroundSize="cover"
          pos="relative"
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="32px"
          color="rgba(255,255,255,0.8)"
        >
          {user.username[0]}
        </Box>
      )}
    </Box>
  );
};

export default Avatar;
