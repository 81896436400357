import { defineStyleConfig } from '@chakra-ui/react';

const InputConfig = defineStyleConfig({
  baseStyle: {
    field: {
      h: '54px',
      px: '16px',
      fontWeight: '500',
      fontSize: '16px',
      border: '1px solid #FFFFFF33',
      borderRadius: '16px',
      color: '#FFFFFF',
      _placeholder: {
        color: '#FFFFFF99',
      },
      _hover: {
        outline: 'none',
      },
      _focusVisible: {
        outline: 'none',
        borderColor: '#FFFFFF',
        boxShadow: 'none',
      },
      _focus: {
        outline: 'none',
        borderColor: '#FFFFFF',
        boxShadow: 'none',
      },
    },
  },
  variants: {},
  defaultProps: {},
});

export default InputConfig;
