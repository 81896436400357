import { RefObject, forwardRef, useEffect, useState } from 'react';

import { Box, Button, Flex, Input, useToast } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/app';

const InviteForm = forwardRef<HTMLDivElement, any>(({}, ref) => {
  const [userReferalLink, setUserReferalLink] = useState('');
  const toast = useToast();
  const user = useAppSelector((store) => store.auth.user);
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserReferalLink(e.target.value);
  };

  const onRefLinkCopy = () => {
    navigator.clipboard.writeText(userReferalLink);
    toast({
      duration: 3000,
      isClosable: true,
      position: 'bottom',
      render: ({ onClose }) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            p="5px 18px !important"
            backgroundColor="#111111"
            borderRadius="10px"
            gap="10px"
            w="min-content"
            whiteSpace="nowrap"
            margin="0 auto 10px"
            onClick={onClose}
          >
            <Box>Invite link copied</Box>
          </Flex>
        );
      },
    });
  };

  useEffect(() => {
    const currentDomain = window.location.origin;
    setUserReferalLink(`${currentDomain}/invite/${user?.referralCode}`);
  }, []);

  return (
    <Flex ref={ref} flexDirection="column" w="100%" gap="12px" maxW="330px">
      <Input
        opacity="0.4"
        boxShadow="none !important"
        _focus={{ opacity: '1', borderColor: '#fff', outline: 'none' }}
        _focusVisible={{
          opacity: '1',
          outline: 'none',
        }}
        value={userReferalLink}
        onChange={onInputChange}
      />
      <Button onClick={onRefLinkCopy}>copy & invite</Button>
    </Flex>
  );
});

export default InviteForm;
