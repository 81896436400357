import { RefObject, forwardRef, useEffect, useState } from 'react';

import { Box, Button, Flex, Grid, Input, useToast } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/app';

const LeaderboardInviteRow = forwardRef<HTMLDivElement, any>(({}, ref) => {
  const [userReferalLink, setUserReferalLink] = useState('');
  const toast = useToast();
  const user = useAppSelector((store) => store.auth.user);
  useEffect(() => {
    const currentDomain = window.location.origin;
    setUserReferalLink(`${currentDomain}/invite/${user?.referralCode}`);
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserReferalLink(e.target.value);
  };

  const onRefLinkCopy = () => {
    navigator.clipboard.writeText(userReferalLink);
    toast({
      duration: 3000,
      isClosable: true,
      position: 'bottom',
      render: ({ onClose }) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            p="5px 18px !important"
            backgroundColor="#111111"
            borderRadius="10px"
            gap="10px"
            w="min-content"
            whiteSpace="nowrap"
            margin="0 auto 10px"
            onClick={onClose}
          >
            <Box>Invite link copied</Box>
          </Flex>
        );
      },
    });
  };

  return (
    <Box p="8px 0" borderBottom="0.5px solid #FFFFFF4D" cursor="pointer" transition="all 0.2s ease-in-out">
      <Grid gap="10px" alignItems="center" justifyContent="space-between" gridTemplateColumns="30px 1fr 58px">
        <Box fontWeight="800" fontFamily="20px" lineHeight="1.2">
          --
        </Box>
        <Box>
          <Flex gap="8px" align="center" fontSize="14px" fontWeight="800" lineHeight="16.94px">
            <Box
              w="36px"
              h="36px"
              borderRadius="12px"
              background="rgba(255, 255, 255, 0.1)"
              fontSize="16px"
              fontWeight="800"
              color="#FFFFFFCC"
              flexShrink="0"
              textTransform="uppercase"
              backgroundPosition="center center"
              backgroundSize="cover"
              pos="relative"
              overflow="hidden"
              _before={{
                content: '""',
                position: 'absolute',
                top: 'calc(50% - 2px)',
                left: '50%',
                width: '12px',
                height: '12px',
                transform: 'translate(-50%, -50%)',
                borderRadius: '50%',
                background: 'rgba(255, 255, 255, 0.5)',
              }}
              _after={{
                content: '""',
                position: 'absolute',
                top: 'calc(100% - 12px)',
                left: '50%',
                width: '22px',
                height: '22px',
                transform: 'translate(-50%, 0%)',
                borderRadius: '50%',
                background: 'rgba(255, 255, 255, 0.5)',
              }}
            />
            <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              your friend
            </Box>
          </Flex>
        </Box>
        <Button
          borderRadius="8px"
          p="8px 10px 12px !important"
          position="relative"
          fontSize="14px"
          onClick={onRefLinkCopy}
          height="36px !important"
          transition="all 0.2s ease-in-out !important"
          _active={{
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
            marginTop: '2px !important',
            height: '34px !important',
            boxShadow: '0px -2px 0px 0px #00000080 inset, 0px 0px 20px 0px #F83C3C40 !important',
            backgroundColor: 'var(--chakra-colors-brand-600) !important',
          }}
        >
          add +
        </Button>
      </Grid>
    </Box>
  );
});

export default LeaderboardInviteRow;
