import { useEffect, useRef, useState } from 'react';

import { Box, Fade, Flex, Img } from '@chakra-ui/react';
import { User } from 'models';

interface FeedbackChatProps {
  user: User;
  isAvailable?: boolean;
  destroyOnClose?: boolean;
  openOnMount?: boolean;
  onClose?: () => void;
}

const notificationAnimation = `
  @keyframes notify {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const FeedbackChat = ({ user, isAvailable = true, destroyOnClose, openOnMount, onClose }: FeedbackChatProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const chatPixelRef = useRef<HTMLDivElement>(null);
  const [gotNewMessage, setGotNewMessage] = useState(false);
  const [isChatUploaded, setIsChatUploaded] = useState(false);

  const removeUneccessaryClasses = () => {
    document.getElementById('fc_frame')?.classList.remove('h-open-notify');
  };

  useEffect(() => {
    if (!isAvailable) return;
    (window as any).fcSettings = {
      onInit: function () {
        (window as any).fcWidget.on('unreadCount:notify', function (resp: { count: number }) {
          setGotNewMessage(resp.count > 0);
          setTimeout(() => {
            removeUneccessaryClasses();
          }, 1000);
        });
        (window as any).fcWidget.on('widget:closed', function () {
          if (onClose) onClose();
        });
      },
    };
  }, [isAvailable]);

  useEffect(() => {
    if (!isAvailable) return;
    (window as any).fcWidgetMessengerConfig = {
      externalId: user.email,
      config: {
        fullscreen: true,
        headerProperty: {
          hideChatButton: true,
          disableNotifications: true,
          disableEvents: true,
        },
      },
    };
  }, [isAvailable]);

  useEffect(() => {
    if (!isAvailable) return;
    const randomHash = Math.random().toString(36).substring(7);
    const script = document.createElement('script');
    const freshchatSource = process.env.REACT_APP_FRESHCHAT_SOURCE || '';
    const version = '6.3.0';
    script.src = `${freshchatSource}?version=${version}&token=${randomHash}`;
    (script as any).chat = true;
    script.id = 'fc_frame_script';

    document.body?.appendChild(script);
  }, [isAvailable]);

  useEffect(() => {
    setTimeout(() => {
      chatPixelRef.current?.click();
      setIsChatUploaded(true);
    }, 1000);
  }, [document.querySelector(`[src="${process.env.REACT_APP_FRESHCHAT_SOURCE}"]`)]);

  const onChatClick = () => {
    if ((window as any).fcWidget) {
      (window as any).fcWidget.user.setEmail(user.email);
      (window as any).fcWidget.user.setFirstName(user.username);
    }
    if ((window as any).fcWidget) {
      (window as any).fcWidget.open();
    }
  };

  // useEffect(() => {
  //   document.getElementById('fc_frame')?.style.setProperty('display', 'block', 'important');
  //   return () => {
  //     document.getElementById('fc_frame')?.style.setProperty('display', 'none', 'important');
  //     document.querySelector(`[src="${process.env.REACT_APP_FRESHCHAT_SOURCE}"]`)?.remove();
  //   };
  // }, [document.getElementById('fc_frame')]);

  useEffect(() => {
    if (isAvailable) {
      document.getElementById('fc_frame')?.style.setProperty('display', 'block', 'important');
      document.getElementById('fc_frame')?.style.setProperty('pointer-events', 'auto', 'important');
      document.getElementById('fc_frame')?.style.setProperty('bottom', '0px', 'important');
      document.getElementById('fc_frame')?.style.setProperty('right', '0px', 'important');
    } else {
      document.querySelectorAll('#fc_frame_script').forEach((el) => el.remove());
      document.getElementById('fc_frame')?.style.setProperty('display', 'none', 'important');
      document.getElementById('fc_frame')?.style.setProperty('bottom', '-100px', 'important');
      document.getElementById('fc_frame')?.style.setProperty('right', '-100px', 'important');
      document.getElementById('fc_frame')?.style.setProperty('pointer-events', 'none', 'important');
    }

    return () => {
      document.querySelectorAll('#fc_frame_script').forEach((el) => el.remove());
      document.getElementById('fc_frame')?.style.setProperty('display', 'none', 'important');

      if (destroyOnClose) {
        document.getElementById('fc_frame')?.remove();
      }
    };
  }, [isAvailable, document.getElementById('fc_frame')]);

  useEffect(() => {
    if (openOnMount && isChatUploaded) {
      onChatClick();
    }
  }, [isChatUploaded]);

  if (!isAvailable) return null;

  return (
    <>
      <Box ref={chatPixelRef} w="1px" h="1px" position="absolute" top="0" left="0" />
      <Flex
        opacity={!!user ? 1 : 0}
        ref={buttonRef}
        position="relative"
        alignItems="center"
        gap="6px"
        margin="0 auto 20px"
        onClick={onChatClick}
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        _active={{ opacity: 0.5 }}
      >
        {gotNewMessage && (
          <Box
            position="absolute"
            top="0px"
            right="-14px"
            bg="red"
            borderRadius="50%"
            w="16px"
            h="16px"
            border="3px solid #242424"
            opacity={1}
            animation={`${notificationAnimation} 2s  forwards 1s`}
          />
        )}

        <Img
          src={`data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.67865 19.1442C2.8543 19.1757 3.03232 19.2003 3.21191 19.2178C3.43016 19.2391 3.65147 19.25 3.875 19.25C5.1903 19.25 6.41947 18.8731 7.45817 18.2213C8.2319 18.4034 9.04179 18.5 9.875 18.5C15.197 18.5 19.625 14.5307 19.625 9.5C19.625 4.46934 15.197 0.5 9.875 0.5C4.55299 0.5 0.125 4.46934 0.125 9.5C0.125 11.9086 1.1496 14.0871 2.79871 15.6923C3.03071 15.9182 3.07607 16.1196 3.05322 16.2349C2.92754 16.8685 2.64187 17.4451 2.23857 17.9211C2.06516 18.1258 2.01427 18.4075 2.10508 18.6599C2.1959 18.9123 2.4146 19.097 2.67865 19.1442ZM6.125 8.375C5.50368 8.375 5 8.87868 5 9.5C5 10.1213 5.50368 10.625 6.125 10.625C6.74632 10.625 7.25 10.1213 7.25 9.5C7.25 8.87868 6.74632 8.375 6.125 8.375ZM8.75 9.5C8.75 8.87868 9.25368 8.375 9.875 8.375C10.4963 8.375 11 8.87868 11 9.5C11 10.1213 10.4963 10.625 9.875 10.625C9.25368 10.625 8.75 10.1213 8.75 9.5ZM13.625 8.375C13.0037 8.375 12.5 8.87868 12.5 9.5C12.5 10.1213 13.0037 10.625 13.625 10.625C14.2463 10.625 14.75 10.1213 14.75 9.5C14.75 8.87868 14.2463 8.375 13.625 8.375Z' fill='white'/%3E%3C/svg%3E%0A`}
          alt="feedback"
          w="20px"
          h="20px"
          objectFit="contain"
        />
        <Box fontSize="18px" lineHeight="25px" fontWeight="800" textShadow="0 -1px 0 0 rgba(0,0,0,0.5)" pb="3px">
          help
        </Box>
      </Flex>
    </>
  );
};

export default FeedbackChat;
