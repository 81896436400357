import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, BoxProps, Flex, Grid } from '@chakra-ui/react';
import { Contest } from 'models';
import routes from 'routes';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as FlameIcon } from 'icons/flame.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr-bold.svg';

import SliderItem from './SliderItem';

type ContestSliderProps = {
  previous: Contest | null;
  current: Contest;
  next?: Contest | null;
} & BoxProps;

const SLIDE_WIDTH = 250;

const ContestSlider: FC<ContestSliderProps> = ({ previous, current, next, ...props }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(true);
  const user = useAppSelector((store) => store.auth.user);

  const items = [previous, current, next].filter((c) => !!c);
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isWheel, setIsWheel] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [startOffset, setStartOffset] = useState(0);

  useEffect(() => {
    if (previous) {
      setOffset(-SLIDE_WIDTH);
    }
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  }, [previous]);

  const maxOffset = SLIDE_WIDTH * (items.length - 1);

  const points = useMemo(() => items.map((_, index) => index * SLIDE_WIDTH), [previous, next]);

  const calcNearest = useCallback(
    (value: number) => points.reduce((prev, curr) => (Math.abs(curr! - value) < Math.abs(prev! - value) ? curr : prev)),
    [points]
  );

  const animationRef = useRef<any>();

  return (
    <Box pos="relative" width="100%" overflow="hidden" pointerEvents="none">
      <Box
        pos="absolute"
        w="250px"
        left="50%"
        transform="translateX(-50%)"
        transition="opacity .2s"
        opacity={disabled ? '0' : '1'}
        mx="auto"
        h="100%"
        pointerEvents="none"
      >
        <Grid
          position="absolute"
          left="0"
          ref={sliderRef}
          w={`${items.length * SLIDE_WIDTH}px`}
          h="100%"
          pointerEvents="auto"
          transform={`translateX(${offset}px)`}
          transition={isDragging || isWheel ? 'none' : 'transform .2s'}
          templateColumns={`repeat(${items.length}, 1fr)`}
          templateRows={`min-content 20px`}
          alignContent="center"
          gap="16px"
          css={{
            touchAction: 'none',
          }}
          onWheel={(e) => {
            if (isDragging) return;
            setIsWheel(true);
            let newOffset = offset - e.deltaX;
            newOffset = newOffset > 0 ? 0 : newOffset < -maxOffset ? -maxOffset : newOffset;
            setOffset(newOffset);
            clearTimeout(animationRef.current);
            animationRef.current = setTimeout(() => {
              setIsWheel(false);
              const nearest = calcNearest(-newOffset) ?? 0;
              setTimeout(() => setOffset(-nearest), 0);
            }, 100);
          }}
          onMouseDown={(e) => {
            setDragStartX(e.clientX);
            setStartOffset(offset);
            setIsDragging(true);
          }}
          onMouseMove={(e) => {
            if (isDragging) {
              const diff = e.clientX - dragStartX;
              let newOffset = startOffset + diff;
              newOffset = newOffset > 0 ? 0 : newOffset < -maxOffset ? -maxOffset : newOffset;
              setOffset(newOffset);
            }
          }}
          onMouseUp={async () => {
            setIsDragging(false);
            setStartOffset(0);
            const nearest = calcNearest(-offset) ?? 0;
            setTimeout(() => {
              setOffset(-nearest);
            }, 0);
          }}
          onTouchStart={(e) => {
            setDragStartX(e.touches[0].clientX);
            setStartOffset(offset);
            setIsDragging(true);
          }}
          onTouchMove={(e) => {
            if (isDragging) {
              const diff = e.touches[0].clientX - dragStartX;
              let newOffset = startOffset + diff;
              newOffset = newOffset > 0 ? 0 : newOffset < -maxOffset ? -maxOffset : newOffset;
              setOffset(newOffset);
            }
          }}
          onTouchEnd={async () => {
            setIsDragging(false);
            setStartOffset(0);
            const nearest = calcNearest(-offset) ?? 0;
            setTimeout(() => {
              setOffset(-nearest);
            }, 0);
          }}
        >
          {items.map((contest) => (
            <Flex
              key={contest!.id}
              height="100%"
              align="center"
              justify="center"
              pointerEvents="revert"
              flexDirection="column"
            >
              <SliderItem contest={contest!} />
            </Flex>
          ))}
          <Flex fontSize="14px" fontWeight="700" style={{ gridColumn: 'span 3', height: 'min-content' }}>
            <Flex
              transform={`translateX(calc(50% + ${offset * -1}px - 5px))`}
              transition={isDragging || isWheel ? 'none' : 'transform .2s'}
              alignItems="center"
              gap="5px"
              as={Link}
              to={routes.streakPromo}
            >
              <Box as={FlameIcon} />
              <Box as="span">{user?.sbcStreakCount}/5 day streak</Box>
              <Box as={RightArrowIcon} h="8px" w="8px" fontWeight="800" />
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContestSlider;
