import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, useToast } from '@chakra-ui/react';
import routes from 'routes';

import { useInviteUserMutation } from 'api/contest';

import { Loader } from 'components/Loader';

const InvitePage = () => {
  const { code } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const [inviteUser, { isLoading, isSuccess, isError }] = useInviteUserMutation();

  useEffect(() => {
    inviteUser({ code: code });
  }, [code]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>Friend successfully added</Box>
            </Flex>
          );
        },
      });

      navigate(routes.home);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>Sign in to accept the invite</Box>
            </Flex>
          );
        },
      });

      navigate(routes.home);
    }
  }, [isError]);

  return (
    <>
      <Loader />
    </>
  );
};

export default InvitePage;
