import { FC, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Box, Text } from '@chakra-ui/react';

import { useGetLeaderboardByPeriodQuery } from 'api/leaderboard';

import LeaderboardPeriod from 'components/leaderboard/LeaderboardPeriod';
import LeaderboardPeriodControls, { Period } from 'components/leaderboard/LeaderboardPeriodControls';
import LeaderboardPeriodHeader from 'components/leaderboard/LeaderboardPeriodHeader';
import LeaderboardSkeleton from 'components/leaderboard/LeaderboardSkeleton';
import LeaderboardFriendsFilter from 'components/leaderboard/leaderboardFriendsFilter';

import { useAppSelector } from 'hooks/app';

const LeaderboardPeriodPage: FC = () => {
  const isAuthicated = useAppSelector((store) => store.auth.isAuthicated);
  const { period } = useParams<{ period: Period }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsLeaderboardId = searchParams.get('leaderboardId');
  const searchParamsFriends = searchParams.get('friends');

  const navigate = useNavigate();
  if (period !== 'weekly' && period !== 'monthly') {
    navigate('/');
    return null;
  }

  const {
    data: periodStat,
    isFetching: isPeriodFetching,
    refetch,
  } = useGetLeaderboardByPeriodQuery(
    {
      period: period!,
      leaderboardId: searchParamsLeaderboardId ? parseInt(searchParamsLeaderboardId) : null,
      friends: searchParamsFriends === 'true',
    },
    { skip: !period }
  );

  useEffect(() => {
    if (periodStat) {
      refetch();
    }
  }, [period]);

  const handleChangeLeaderboardId = (id: number) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('leaderboardId', `${id}`);
      return newParams;
    });
  };

  const isFetching = isPeriodFetching;
  return (
    <Box display="flex" flexDirection="column" h="100%" gridTemplateRows="auto auto 1fr" px="12px">
      {isAuthicated ? (
        <LeaderboardFriendsFilter />
      ) : (
        <Text textAlign="left" variant="pageTitle">
          leaderboard
        </Text>
      )}
      <LeaderboardPeriodControls innitialActivePeriod={period} />
      {isFetching && <LeaderboardSkeleton />}
      {periodStat && !isFetching && (
        <>
          <LeaderboardPeriod
            key={periodStat?.currentLeaderboard?.id}
            stat={periodStat}
            showLeaderboard={!!periodStat}
            showMySquad={!!periodStat.me}
          />
        </>
      )}
      <LeaderboardPeriodHeader
        disabled={isFetching}
        stat={periodStat}
        onNext={handleChangeLeaderboardId}
        onPrev={handleChangeLeaderboardId}
        type={period}
      />
    </Box>
  );
};
export default LeaderboardPeriodPage;
