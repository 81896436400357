import { CSSProperties } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Card, ContestParticipant } from 'models';

import BoosterCard from 'components/builder/BoosterCard';
import PlayerCard from 'components/builder/player-card/PlayerCard';

import { useAppSelector } from 'hooks/app';

import { selectSelectedBooster } from 'store/slices/contest';

interface SquadDeckProps {
  cards: Card[];
  participation: ContestParticipant;
}

const cardStyles: { [key: string]: CSSProperties } = {
  firstName: {
    textTransform: 'uppercase',
    color: 'inherit',
    fontSize: '10px',
  },
  lastName: {
    display: 'none',
  },
  image: {
    height: '136px',
  },
  info: {
    marginTop: '2px',
  },
};

const SquadDeck = ({ cards, participation }: SquadDeckProps) => {
  const selectedBooster = useAppSelector(selectSelectedBooster);
  return (
    <>
      <Box
        pos="relative"
        fontFamily="JetBrains Mono"
        fontSize="12px"
        fontWeight="500"
        textAlign="center"
        mt="20px"
        zIndex="10"
      >
        STARTERS
      </Box>

      <Flex alignItems="center" justifyContent="center" mt="12px" zIndex="10">
        {cards
          .filter((card) => card.status === 'start')
          .map((card, index) => (
            <PlayerCard
              card={card}
              width="108px"
              height="162px"
              zIndex={index === 1 ? 51 : 50}
              color="#9B9292"
              transform={
                index === 0 ? 'rotate(-8deg) translateY(9px)' : index === 2 ? 'rotate(8deg) translateY(9px)' : ''
              }
              styles={cardStyles}
              key={card.id}
            />
          ))}
      </Flex>

      <Flex alignItems="center" justifyContent="center" mt="15px" gap="15px" pos="relative" zIndex="10">
        <Box
          fontFamily="JetBrains Mono"
          fontSize="12px"
          fontWeight="500"
          textAlign="center"
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-170px, -50%)"
        >
          BENCH
        </Box>

        <Box
          fontFamily="JetBrains Mono"
          fontSize="12px"
          fontWeight="500"
          textAlign="center"
          pos="absolute"
          top="50%"
          right="50%"
          transform="translate(170px, -50%)"
        >
          BOOST
        </Box>

        <PlayerCard
          card={cards.find((card) => card.status === 'bench')!}
          height="162px"
          width="108px"
          transform="rotate(-4deg) translateY(0px)"
          bg="#4F4F4F"
          color="#D2C9C9"
          styles={cardStyles}
        />

        <BoosterCard
          height="162px"
          width="108px"
          transform="rotate(4deg) translateY(0px)"
          booster={participation.booster ?? selectedBooster ?? undefined}
          isDisabled={participation.status !== 'completion_pending'}
        />
      </Flex>
    </>
  );
};

export default SquadDeck;
