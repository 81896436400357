import { FC, useEffect, useRef, useState } from 'react';

import { Box, Fade, Image, ScaleFade, keyframes } from '@chakra-ui/react';
import confetti from 'canvas-confetti';

import { Loader } from 'components/Loader';
import { BoosterPackProps } from 'components/pages/BuilderPage/types';

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
`;

const fadeInAnimation = keyframes`
  0% { 
    scale: 0.8;
    opacity: 0;
  }
 
  100% { 
    scale: 1;
    opacity: 1; 
  }
`;

const simpleFadeInAnimation = keyframes`
  0% { 
    opacity: 0;
  }
 
  100% { 
    opacity: 1; 
  }
`;

const fadeOutAnimation = keyframes`
  0% { 
    opacity: 1; 
    scale: 1;
  }
 
  100% { 
    opacity: 0; 
    scale: 0.9;
  }
`;

const smallPackRotate = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(8deg);
  }
  50% {
    transform: rotateY(-8deg);
  }
  75% {
    transform: rotateY(8deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const blinkPosition = keyframes`
  0% {
    transform: translate(-50%, -50%);
    left: 50%;
    opacity: 0;
  }
  25% {
    transform: translate(-50%, -50%);
    left: calc(50% - 60px);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%);
    left: calc(50% + 60px);
    opacity: 1;
  }
  75% {
    transform: translate(-50%, -50%);
    left: calc(50% - 60px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%);
    left: 50%;
    opacity: 0;
  }
`;

const fromBottomToTop = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const packToBottom = keyframes`
  0% {
    transform: translateY(0);
    scale: 1;
  }
  100% {
    transform: translateY(calc(100% - 100px));
    scale: 1.2;
  }
`;

const shadowWaving = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(255,108,109,1);
  }
  50% {
    box-shadow: 0 0 20px rgba(255,108,109,1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255,108,109,1);
  }
`;

const fadeTopAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const slideBottomAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200%);
  }
`;

const BoosterPack: FC<BoosterPackProps> = ({ onPackClick, image, isFirstSpin, cardCover, onPackCut, boosters }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [isPackClicked, setIsPackClicked] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(-27);
  const [isPackOpened, setIsPackOpened] = useState(false);
  const [isCardOpened, setIsCardOpened] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [hidePack, setHidePack] = useState(false);

  useEffect(() => {
    var end = Date.now() + 5 * 1000;
    const colors = ['#f93c3d', '#f9f9f9'];
    if (isPackOpened && canvasRef.current) {
      (function frame() {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { y: 1, x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { y: 1, x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    }
  }, [isPackOpened]);

  useEffect(() => {
    if (isPackOpened && boosters) {
      setTimeout(() => {
        setHidePack(true);
        onPackCut?.();
      }, 800);
    }
  }, [isPackOpened, boosters]);

  const onImageClickHandler = () => {
    if (isClicked) return;
    setIsClicked(true);
    setIsCardOpened(true);
    onPackClick?.();
  };

  const onPackClickHandler = () => {
    confetti({
      particleCount: 200,
      spread: 200,
      origin: { y: 0.6 },
      colors: ['#f93c3d', '#f9f9f9'],
    });
    // onPackClick?.();
    setIsPackClicked(!isPackClicked);
  };

  const onDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;

    setStartX(clientX);
    setCurrentX(clientX);
  };

  const onDragMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (isDragging) {
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;

      if (clientX < 0) {
        return;
      }
      setCurrentX(clientX);
    }
  };

  const onDragEnd = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(false);
    const clientX = 'changedTouches' in e ? e.changedTouches[0].clientX : e.clientX;
    const endX = clientX;
    if (endX - startX > 100) {
      setIsPackOpened(true);
      onPackClick?.();
    } else {
      setCurrentX(-27);
      setStartX(0);
    }
  };

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent | TouchEvent) => {
      if (isDragging) {
        onDragEnd(e as any);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging]);

  setTimeout(() => {
    setIsDescriptionVisible(true);
  }, 1200);

  return (
    <>
      {isPackClicked && !isPackOpened && (
        <Box
          animation={isClicked ? `${fadeOutAnimation} .8s ease forwards` : ''}
          textAlign="center"
          fontSize="20px"
          lineHeight="24px"
          fontWeight="800"
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          transform={'translate(-50%, -50%)'}
          w="100%"
        >
          👉🏻 Swipe to cut the pack
        </Box>
      )}
      {!hidePack && (
        <Box
          width="100%"
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          animation={`${fromBottomToTop} 0.7s ease forwards ${!isPackClicked ? ', ' + bounceAnimation + ' 2s ease-in-out infinite 0.9s' : ''}  ${isPackClicked ? ', ' + packToBottom + ' .8s ease-in-out forwards' : ''}`}
          marginBottom="20px"
          onClick={onPackClickHandler}
          css={{
            perspective: '1000px',
          }}
          maxW="500px"
        >
          {isPackClicked && !isPackOpened && (
            <Box
              style={{
                zIndex: 9999999999,
              }}
              animation={`${simpleFadeInAnimation} .8s ease forwards`}
            >
              <Box
                position="absolute"
                top="calc(50% - 170px)"
                left="50%"
                transform="translate(-50%, -50%)"
                bgColor="#fff"
                zIndex="9999999999"
                w={isPackClicked ? 'calc(100% - 110px)' : '0'}
                h="6px"
                transition="all .4s ease-in-out"
                boxShadow={'0 0 10px rgba(255,255,255,1)'}
                borderRadius="3px"
                maxW="320px"
              >
                <Box
                  w="60px"
                  h="60px"
                  borderRadius="50%"
                  bgColor="#f93c3d"
                  boxShadow={isPackClicked ? '0 0 10px rgba(249,60,61,0.5)' : 'none'}
                  position="absolute"
                  onMouseDown={onDragStart}
                  onMouseMove={onDragMove}
                  onMouseUp={onDragEnd}
                  onTouchStart={onDragStart}
                  onTouchMove={onDragMove}
                  onTouchEnd={onDragEnd}
                  style={{ left: `${currentX - startX}px`, top: '-27px' }}
                  animation={isPackClicked ? shadowWaving + ' 1s ease-in-out infinite' : ''}
                />
              </Box>
            </Box>
          )}
          <Box
            position="absolute"
            top="calc(50% - 140px)"
            transform="translate(-50%, -50%)"
            bgImage="linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 80%, rgba(255,255,255,0) 100%)"
            filter="blur(30px)"
            zIndex="2"
            w="100px"
            h="60px"
            opacity="0"
            transition="all .4s ease-in-out"
            animation={!isPackClicked ? `${blinkPosition} 9s ease-in-out infinite 1s` : ''}
          />

          <Image
            src={image}
            alt="booster pack"
            height={isPackOpened ? 'calc(100% - 130px)' : '100%'}
            width="320px"
            objectFit="cover"
            objectPosition="center"
            position="relative"
            zIndex="1"
            animation={`${fadeInAnimation} .8s ease forwards ${!isPackClicked ? ',' + smallPackRotate + ' 9s ease-in-out infinite 1s' : ''} ${isPackOpened ? ' , ' + slideBottomAnimation + ' 0.8s ease-in-out forwards' : ''}`}
            loading="eager"
            css={{
              transformStyle: 'preserve-3d',
            }}
          />
          <Image
            src={image}
            alt="booster pack"
            height="70px"
            width="320px"
            top="0px"
            objectFit="cover"
            objectPosition="top"
            position="absolute"
            zIndex="1"
            loading="eager"
            opacity={isPackClicked ? '1' : '0'}
            css={{
              transformStyle: 'preserve-3d',
            }}
            animation={isPackOpened ? `${fadeTopAnimation} 1s ease forwards` : ''}
          />
          <Box
            bgImage="radial-gradient(circle, rgba(249, 60, 61, 1) 0%, rgba(249, 60, 61, 0) 60%)"
            position={'absolute'}
            bottom={'0'}
            h="400px"
            w="400px"
            borderRadius={'50%'}
            transition={'opacity .4s ease-in-out'}
            opacity={isPackClicked && !isPackOpened ? '1' : '0'}
            transform={`scale(${currentX / 100})`}
          />
          <Box
            as="canvas"
            ref={canvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              zIndex: 3,
            }}
          />
        </Box>
      )}
      <Fade in={isDescriptionVisible && !isPackClicked}>
        <Box
          animation={isClicked ? `${fadeOutAnimation} .8s ease forwards` : ''}
          textAlign="center"
          fontSize="20px"
          lineHeight="24px"
          fontWeight="800"
        >
          tap to open 👆
        </Box>
      </Fade>
    </>
  );
};

export default BoosterPack;
