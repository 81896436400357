import React, { FC, useEffect, useRef, useState } from 'react';

import { Box, Button, Fade, Flex, Image, SlideFade, Text, keyframes, position } from '@chakra-ui/react';
import { Booster } from 'models';

import Spinner from 'components/Spinner';
import BoosterCard from 'components/booster/BoosterCard';
import BoostersFall from 'components/pages/BuilderPage/BoostersFall';
import CardBlaster from 'components/pages/BuilderPage/CardBlaster';
import { BoosterCardsProps } from 'components/pages/BuilderPage/types';

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
`;

const smallRotate = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(10deg);
  }
  50% {
    transform: rotateY(-10deg);
  }
  75% {
    transform: rotateY(10deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const fadeInCard = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInPerspective = keyframes`
  0% {
  opacity: 0;
    transform: scale(0.9) ;
  }
  100% {
    opacity: 1;
    transform: scale(1)  ;
  }
`;

const fadeOutCard = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const cardFadeIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

const cardFadeOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
`;

const BoosterCards: FC<BoosterCardsProps> = ({ boosters, cardCover, onBoosterShow, ...props }) => {
  const [activeBooster, setActiveBooster] = useState<number | null>(null);
  const [removingBooster, setRemovingBooster] = useState<number | null>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const [boosterCardPosition, setBoosterCardPosition] = useState<{ x: number; y: number } | null>(null);
  const [showBooster, setShowBooster] = useState(false);
  const [startCardFall, setStartCardFall] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (cardRef.current) {
        const rect = cardRef.current.getBoundingClientRect();
        setBoosterCardPosition({ x: rect.x, y: rect.y });
      }
    }, 1000);
  }, [cardRef, activeBooster]);

  const handleNextClick = (id: number) => {
    setRemovingBooster(activeBooster);
    setTimeout(() => {
      setActiveBooster(id);
      setRemovingBooster(null);
    }, 800);
  };

  const handleOnClose = () => {
    setRemovingBooster(activeBooster);
    setTimeout(() => {
      setRemovingBooster(null);
      props.onClose && props.onClose();
    }, 800);
  };

  useEffect(() => {
    if (boosters) {
      setActiveBooster(boosters[0].id);
    }
  }, [boosters]);

  return (
    <>
      <Box w="100%" padding="13px 0" zIndex={2}>
        <Fade in={true}>
          <Text
            fontSize="18px"
            fontWeight="800"
            lineHeight="21.6px"
            variant="boosterTitle"
            textAlign="center"
            color="#fff"
          >
            you got new boost! 🚀
          </Text>
        </Fade>
      </Box>
      <Flex
        flexDirection="column"
        animation={`${fadeInCard} .8s ease-in`}
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CardBlaster
          cardCover={cardCover}
          boosters={boosters}
          position={boosterCardPosition}
          onLoadEnd={() => {
            if (boosterCardPosition !== null) {
              setStartCardFall(true);
            }
          }}
        />

        <BoostersFall
          cardCover={cardCover}
          start={startCardFall}
          boosters={boosters}
          activeBooster={activeBooster}
          position={boosterCardPosition}
          onLoadEnd={() => {
            setShowBooster(true);
            onBoosterShow && onBoosterShow();
          }}
        />

        {boosters?.map((booster, index) => (
          <React.Fragment key={`${booster.id}-${index}`}>
            {activeBooster === booster.id && (
              <Flex
                flexDirection="column"
                gap="32px"
                animation={`${removingBooster === booster.id ? fadeOutCard : fadeInCard} .3s ease-in forwards`}
              >
                <Flex
                  transformOrigin="50% 50%"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  animation={`${fadeInPerspective} .3s ease-in forwards  ${showBooster ? ', ' + bounceAnimation + ' 2s ease-in-out infinite .3s' : ''} `}
                  css={{
                    perspective: '1000px',
                    transformStyle: 'preserve-3d',
                  }}
                >
                  <BoosterCard
                    ref={cardRef}
                    key={booster.id}
                    booster={booster}
                    aspectRatio={'1/1.5'}
                    {...props}
                    position="relative"
                    zIndex={1}
                    type="spin"
                    w="200px"
                    opacity={showBooster ? 1 : 0}
                    animation={`${removingBooster === booster.id ? cardFadeOut : cardFadeIn} .3s ease-in forwards ${showBooster ? ', ' + smallRotate + ' 9s ease-in-out infinite .3s' : ''} `}
                    css={{
                      perspective: '1000px',
                      transformStyle: 'preserve-3d',
                    }}
                  />
                  {booster.isGlowing && (
                    <Spinner
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%) translateZ(-40px)"
                      transition={`opacity 0.5s ease`}
                      opacity={showBooster ? 1 : 0}
                      css={`
                        svg,
                        path {
                          fill: ${booster.backgroundColor};
                        }
                      `}
                    />
                  )}
                </Flex>
                <Flex flexDirection="column" gap="16px" zIndex={2}>
                  {/* {boosters.length > 1 && (
                    <Text fontSize="14px" fontWeight="500" textAlign="center" marginBottom="0" opacity="0.5">
                      Card {index + 1} of {boosters.length}
                    </Text>
                  )} */}
                  <Flex flexDirection="column" gap="8px">
                    {/* <Text fontSize="24px" fontWeight="800" lineHeight="29px" textAlign="center" color="#fff">
                      {booster.name}
                    </Text> */}
                    <SlideFade in={showBooster} offsetY="20px">
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="18px"
                        textAlign="center"
                        color="rgba(255, 255, 255, 0.8)"
                        css={{ textWrap: 'balance' }}
                      >
                        {booster.description}
                      </Text>
                    </SlideFade>
                  </Flex>
                  <SlideFade in={showBooster} offsetY="20px">
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="18px"
                      textAlign="center"
                      color="rgba(255, 255, 255, 0.8)"
                      opacity={0.5}
                      css={{ textWrap: 'balance' }}
                    >
                      Apply after building the squad.
                      <br />
                      Single-use item · {booster.league.toUpperCase()}
                    </Text>
                  </SlideFade>
                </Flex>
                {index !== boosters.length - 1 ? (
                  <Flex
                    justifyContent="center"
                    onClick={() => {
                      if (boosters[index + 1]) {
                        handleNextClick(boosters[index + 1].id);
                      }
                    }}
                  >
                    <SlideFade in={showBooster} offsetY="20px">
                      <Button>next</Button>
                    </SlideFade>
                  </Flex>
                ) : (
                  <Flex justifyContent="center">
                    <SlideFade in={showBooster} offsetY="20px">
                      <Button onClick={() => handleOnClose()}>to the game</Button>
                    </SlideFade>
                  </Flex>
                )}
              </Flex>
            )}
          </React.Fragment>
        ))}
      </Flex>
    </>
  );
};

export default BoosterCards;
