import { useEffect, useRef, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Grid, Img, Link, SlideFade, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';

import { useGetUserQuery, useUpdateUserAvatarMutation } from 'api/user';

type FormData = {
  avatarUrl: string;
};

const EditAvatar = () => {
  const [isAvatarEditorOpen, setIsAvatarEditorOpen] = useState(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedArea);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const [updateUserAvatar, updateUserAvatarReq] = useUpdateUserAvatarMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const {
    isError: isUpdateError,
    error: updateError,
    isLoading: isUserUpdating,
    isSuccess,
    originalArgs,
    data,
  } = updateUserAvatarReq;

  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [blob, setBlob] = useState<Blob | null>(null);

  const imgRef = useRef<HTMLImageElement>(null);

  const onUploadFileHandler = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const formData = new FormData();
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        formData.append('avatar', file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target?.result as string;
          setImgSrc(base64String);

          updateUserAvatar({ avatar: formData as any });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const uploadWithFetch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('avatar', file);
    const token = Cookies.get('sbc_access_token');
    const response = await fetch('https://clutchpoints-users-test.4taps.me/user/', {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
    }

    return response;
  };

  const handleCapture = async (croppedAreaPixels: Area | null) => {
    if (imgRef.current && croppedAreaPixels) {
      // Use a CORS proxy to fetch the image data
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      const imageUrl = imgRef.current.src;
      const response = await fetch(proxyUrl + imageUrl);
      const blob = await response.blob();
      const imageBitmap = await createImageBitmap(blob);

      const canvas = document.createElement('canvas');
      canvas.width = imgRef.current.width;
      canvas.height = imgRef.current.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(imageBitmap, 0, 0);

      const croppedCanvas = document.createElement('canvas');
      const croppedCtx = croppedCanvas.getContext('2d');

      croppedCanvas.width = croppedAreaPixels.width;
      croppedCanvas.height = croppedAreaPixels.width;

      croppedCtx?.drawImage(
        canvas,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.width,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.width
      );

      const croppedDataUrl = croppedCanvas.toBlob(
        (blob) => {
          if (!blob) {
            throw new Error('Canvas is empty');
          }
          setBlob(blob);
          return blob;
        },
        'image/png',
        1
      );

      setImgSrc(croppedCanvas.toDataURL());
    }
  };

  let backLink = '/';

  if (location.state && location.state.from) {
    backLink = location.state.from;
  }

  const filter = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers,
  });

  const onSubmit = () => {
    if (!imgSrc) {
      return;
    }
    // updateUser({ ...user, avatarUrl: imgSrc });
  };

  useEffect(() => {
    if (imgSrc) {
      onSubmit();
    }
  }, [imgSrc]);

  useEffect(() => {
    if (isUserUpdating || isUserLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isUserUpdating, isUserLoading]);

  useEffect(() => {
    if (isSuccess && !isUpdateError) {
      setTimeout(() => {
        navigate(backLink, {
          state: {
            avatarUrlUpdateStatus: { message: 'Name Changed', isSuccess: !isUpdateError },
          },
        });
      }, 1000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && data) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>Avatar changed</Box>
            </Flex>
          );
        },
      });
      setTimeout(() => {
        window.history.replaceState({}, document.title);
      }, 4000);
    }
  }, [isSuccess, data]);

  return (
    <Flex
      flexDirection="column"
      pos="absolute"
      w="100%"
      maxW="500px"
      h="100%"
      backgroundColor="#242424"
      zIndex="999"
      top="0px"
      left="50%"
      p="10px 12px"
      transform="translateX(-50%)"
    >
      {imgSrc && <Img src={imgSrc} zIndex={9999999} aspectRatio="1 / 1" objectFit="contain" objectPosition="center" />}
      {isAvatarEditorOpen && (
        <Flex
          backgroundColor="#242424"
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="999"
          flexDirection="column"
        >
          {user?.avatarUrl && (
            <Img
              pos="fixed"
              top="0"
              left="0"
              right="0"
              bottom="0"
              objectFit="contain"
              objectPosition="center"
              src={user.avatarUrl}
              ref={imgRef}
              zIndex="-1"
            />
          )}
          {user?.avatarUrl && (
            <Cropper
              image={user.avatarUrl}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="rect"
              objectFit="contain"
              cropSize={{ width: 260, height: 260 }}
            />
          )}
          <Button onClick={() => handleCapture(croppedAreaPixels)} pos="absolute" bottom="20px" left="0" right="0">
            Save
          </Button>
        </Flex>
      )}
      <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" alignItems="center" justifyContent="center">
        <Box />
        <Box fontSize="16px" lineHeight="1.2" fontWeight="800" textAlign="center">
          edit avatar
        </Box>
        <Box
          as={Link}
          href={backLink}
          display="flex"
          alignItems="center"
          aspectRatio="1/1"
          fontSize="14px"
          lineHeight="1.2"
          fontWeight="500"
          textAlign="right"
          cursor="pointer"
          textDecoration="none !important"
          _active={{
            opacity: '0.5',
            transition: 'opacity 0.2s',
          }}
        >
          close
        </Box>
      </Grid>

      <Flex flexDirection="column" gap="24px" flex="1" justifyContent="flex-start" p="0 12px" pos="relative" mt="18px">
        <Flex flexDirection="column" gap="16px" pos="relative" alignItems="center">
          <Box onClick={() => setIsAvatarEditorOpen(!isAvatarEditorOpen)}>
            {user?.avatarUrl ? (
              <Box
                w="72px"
                h="72px"
                borderRadius="24px"
                fontSize="16px"
                fontWeight="800"
                color="#FFFFFFCC"
                flexShrink="0"
                textTransform="uppercase"
                backgroundImage={user.avatarUrl}
                backgroundPosition="center center"
                backgroundSize="cover"
                pos="relative"
                overflow="hidden"
              />
            ) : (
              <Box
                w="72px"
                h="72px"
                borderRadius="24px"
                background="#3a3a3a"
                fontWeight="800"
                flexShrink="0"
                textTransform="uppercase"
                backgroundPosition="center center"
                backgroundSize="cover"
                pos="relative"
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="32px"
                color="rgba(255,255,255,0.8)"
              >
                {user?.username[0]}
              </Box>
            )}
          </Box>
          <input type="file" accept="image/*" onChange={uploadWithFetch} />
          <Button onClick={onUploadFileHandler} w="100%" isDisabled={!!errorMessage || !!data} isLoading={isLoading}>
            upload
          </Button>
          {errorMessage && (
            <SlideFade
              in={true}
              offsetY="-20px"
              style={{ position: 'absolute', top: 'calc(100% + 20px)', textAlign: 'center', width: '100%' }}
            >
              <Box w="100%" color="#f83c3c">
                {errorMessage}
              </Box>
            </SlideFade>
          )}
        </Flex>
      </Flex>
      <Box fontSize="14px" fontWeight="500" lineHeight="1.2" textAlign="center" opacity="0.6" mb="14px">
        it'll be up on the leaderboards
        <br />
        and squad screen
      </Box>
    </Flex>
  );
};

export default EditAvatar;
