import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { Navigate } from 'react-router-dom';

import { Box, Button, Fade, Flex, Input, Text, keyframes, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import routes from 'routes';

import InviteForm from 'components/InviteForm';
import { Loader } from 'components/Loader';
import Squad from 'components/builder/Squad/Squad';

import { useAppSelector } from 'hooks/app';
import useBoosters from 'hooks/useBoosters';
import useBuilder from 'hooks/useBuilder';

import { ReactComponent as ClutchpointsIcon } from 'icons/clutchpoints.svg';

import Tutorial from './BuilderPage/ui/Tutorial';

const bounceAnimation = keyframes`
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-18px);}
	60% {transform: translateY(-7px);}
`;

const SquadPage = () => {
  const [showTutorial, setShowTutorial] = useState(false);
  const { contest, participation, isLoading, finish, cardsWithStatus } = useBuilder();
  const { submitBooster, refetchBoosters, boosters } = useBoosters();
  const refFormRef = useRef<HTMLDivElement>(null);

  const user = useAppSelector((store) => store.auth.user);

  const setTutorialVisibility = () => {
    const lastShown = localStorage.getItem('sbc_tutorial_2');
    const today = new Date();

    if (lastShown) {
      const lastShownDate = new Date(lastShown);
      const diffTime = Math.abs(today.getTime() - lastShownDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays >= 3) {
        setShowTutorial(true);
        localStorage.setItem('sbc_tutorial_2', today.toDateString());
      }
    } else {
      setShowTutorial(true);
      localStorage.setItem('sbc_tutorial_2', today.toDateString());
    }
  };

  // todo: remove it in the future when we will update hooks logic
  useEffect(() => {
    if (!!boosters) {
      refetchBoosters();
    }
  }, []);

  const handleTutorialToggle = () => {
    setShowTutorial((prev) => !prev);
  };

  const handleScrollToRefForm = () => {
    refFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (contest?.status === 'locked' || contest?.status === 'completed') {
    return <Navigate to={routes.leaderboard} replace />;
  }

  if (contest?.status !== 'active') {
    return <Navigate to={routes.home} replace />;
  }

  if (!participation) {
    return null;
  }

  if (participation?.status === 'cards_pending') {
    return <Navigate to={routes.builder} replace />;
  }

  const submitted = participation.status === 'completed';

  if (!submitted) {
    setTutorialVisibility();
  }

  return (
    <>
      {!submitted && (
        <Fade
          unmountOnExit
          in={showTutorial}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
          }}
        >
          <Tutorial
            onOk={handleTutorialToggle}
            title={
              <Flex alignItems="center" justifyContent="center" flexDirection="column" gap="12px">
                <Text variant="tutorialPhase">phase 2 of 2</Text>
                <Text variant="tutorialTitle">boost your squad</Text>
              </Flex>
            }
            content={
              <>
                <Text variant="tutorialText">
                  ⏫ <b>Multiply coins</b> from squad PTS
                </Text>
                <Text variant="tutorialText">
                  ⚡ <b>Pick 1 extra stat</b> with a booster, earn coins on top of PTS
                </Text>
                <Text variant="tutorialText">
                  🎰 <b>Claim free boosts</b> for daily logins, invites, and more
                </Text>
              </>
            }
          />
        </Fade>
      )}

      <Box pos="relative" overflow="hidden">
        <Squad
          participation={participation}
          cards={cardsWithStatus}
          pos="relative"
          overflow="hidden"
          title={submitted ? 'Start Bench Cut' : 'Build your squad'}
          description={
            submitted
              ? `${dayjs(contest.startsAt).tz('America/New_York').format('MMM D')} · ${user?.username}`
              : 'Earn from total PTS and boosts'
          }
          contestStatus={contest.status}
          showTutorial={handleTutorialToggle}
        />

        {participation.status === 'completion_pending' && (
          <Button
            pos="fixed"
            bottom="20px"
            left="50%"
            transform="translateX(-50%)"
            zIndex="100"
            onClick={async () => {
              ReactGA.event({
                category: 'Squad',
                action: 'submit squad',
              });
              try {
                await submitBooster();
                await finish();
              } catch (e) {
                console.error(e);
              }
            }}
            isDisabled={isLoading}
          >
            submit the squad
          </Button>
        )}
        {participation.status === 'completed' && user?.showReferralForm && (
          <Flex mt="16px" flexDirection="column" alignItems="center" justifyContent="center" gap="16px">
            <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="12px">
              <Box
                pos="relative"
                w="24px"
                h="24px"
                animation={`${bounceAnimation} 2s infinite both`}
                _after={{
                  content: '""',
                  width: '2px',
                  height: '11px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: 'calc(50% - 0px)',
                  left: 'calc(50% + 0.5px)',
                  transform: 'translate(100%, -50%) rotate(45deg)',
                  borderRadius: '2px',
                }}
                _before={{
                  content: '""',
                  width: '2px',
                  height: '11px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: 'calc(50% - 0px)',
                  right: 'calc(50% + 0.5px)',
                  transform: 'translate(-100%, -50%) rotate(-45deg)',
                  borderRadius: '2px',
                }}
              />
              <Box
                onClick={handleScrollToRefForm}
                as="span"
                fontSize="18px"
                lineHeight="1.2"
                fontWeight="800"
                textAlign="center"
                style={{ textWrap: 'balance' }}
              >
                Compete with friends and rule the leaderboard
              </Box>
            </Flex>

            <InviteForm ref={refFormRef} />
          </Flex>
        )}
        <Box as={ClutchpointsIcon} display="block" mx="auto" mt="32px" mb="24px" />
      </Box>
    </>
  );
};

export default SquadPage;
