import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Fade, Flex, SlideFade } from '@chakra-ui/react';
import { ContestParticipant, LeaderboardStat } from 'models';
import checkMeIsEmpty from 'utils/checkMeIsEmpty';

import LeaderboardInviteRow from './LeaderboardInviteRow';
import LeaderboardRow from './LeaderboardRow';
import MySquad from './MySquad';
import NoParticipants from './NoParticipants';

type Props = {
  stat: LeaderboardStat;
  participant?: ContestParticipant;
  showLeaderboard?: boolean;
  showMySquad?: boolean;
  showInviteRow?: boolean;
};

const Leaderboard: FC<Props> = ({
  stat,
  showLeaderboard = true,
  showMySquad = true,
  participant,
  showInviteRow = false,
}) => {
  const [meIsEmpty, setMeIsEmpty] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState<boolean[]>([]);

  const allParticipants = stat.participants;
  const friends =
    stat.me && allParticipants.length <= 19
      ? [...allParticipants, stat.me].sort((a, b) => (a.place ?? 0) - (b.place ?? 0))
      : allParticipants;
  const participants = searchParams.get('friends') === 'true' ? friends : allParticipants;

  const myPlaceAmongFriends = participants.findIndex((u) => u.user.id === stat.me?.user.id) + 1;

  useEffect(() => {
    if (stat.me) {
      setMeIsEmpty(checkMeIsEmpty(stat.me));
    }
  }, [stat.me]);

  // useEffect(() => {
  //   if (participants) {
  //     const openBoosters = async () => {
  //       for (let i = 0; i < participants.length; i++) {
  //         await new Promise((resolve) => setTimeout(resolve, 50));
  //         setIsOpen((prev) => {
  //           const newIsOpen = [...prev];
  //           newIsOpen[i] = true;
  //           return newIsOpen;
  //         });
  //       }
  //     };
  //     openBoosters();
  //   }
  // }, [participants, stat.currentContest]);

  return (
    <>
      {(showLeaderboard || showMySquad) && (
        <Flex flexDirection="column" flex="1">
          {participant && showMySquad && (
            <MySquad
              myPlaceAmongFriends={myPlaceAmongFriends}
              participant={participant}
              contestStatus={stat.currentContest.status}
            />
          )}
          {showLeaderboard && participants.length > 0 && (
            <Flex flexDirection="column">
              {
                <>
                  {participants.map((p, index) => (
                    <Fade key={`${p.id}-${index}`} in={true} unmountOnExit>
                      <LeaderboardRow position={index} participant={p} />
                    </Fade>
                  ))}
                </>
              }
            </Flex>
          )}
          {showInviteRow && (
            <Flex flexDirection="column">
              {searchParams.get('friends') === 'true' &&
                Array.from({ length: 2 }).map((_, index) => <LeaderboardInviteRow key={`invite-row-${index}`} />)}
            </Flex>
          )}
          {showLeaderboard && stat.nextContest !== undefined && (stat.currentContest.participantsCount ?? 0) <= 0 && (
            <Flex h="100%" flexDirection="column">
              <NoParticipants nextContest={stat.nextContest} />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default Leaderboard;
