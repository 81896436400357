import { Box, Flex, Text, keyframes } from '@chakra-ui/react';

import { useGetBoostersQuery } from 'api/contest';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as FlameIcon } from 'icons/flame.svg';

import Header from './Header';
import Slider from './Slider';

const fadeInAnimation = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StreakPromoPage = () => {
  const user = useAppSelector((store) => store.auth.user);

  return (
    <Flex flexDirection="column" alignItems="center" gap="12px" overflow="hidden" p="0 0 20px">
      <Header />
      <Flex flexDirection="column" alignItems="center" gap="12px">
        <Flex alignItems="center" gap="5px" opacity="0" animation={`${fadeInAnimation} 0.5s ease-out forwards`}>
          <Box as={FlameIcon} />
          <Box fontWeight="700" as="span">
            {user?.sbcStreakCount}/5 day streak
          </Box>
        </Flex>
        <Flex flexDirection="column" alignItems="center" gap="16px">
          <Text
            fontSize="28px"
            fontWeight="800"
            lineHeight="1.2"
            textAlign="center"
            css={{ textWrap: 'balance' }}
            opacity="0"
            animation={`${fadeInAnimation} 0.5s ease-out forwards 0.1s`}
          >
            Take over the boards with{' '}
            <Box
              as="span"
              sx={{
                background: 'linear-gradient(90deg, rgba(255, 0, 0, 1),rgba(255, 161, 0, 1))',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Blazing
            </Box>{' '}
            booster
          </Text>
          <Text
            fontSize="16px"
            lineHeight="1.2"
            fontWeight="700"
            textAlign="center"
            css={{ textWrap: 'balance' }}
            opacity="0"
            animation={`${fadeInAnimation} 0.5s ease-out forwards 0.2s`}
          >
            Hit a 5-day streak and grab unique pack with 3 top-tier boosters
          </Text>
        </Flex>
      </Flex>

      <Flex w="100%" flex="2" opacity="0" animation={`${fadeAnimation} 0.5s ease-out forwards 0.2s`}>
        <Slider />
      </Flex>

      <Text
        fontSize="12px"
        lineHeight="1.2"
        fontWeight="500"
        textAlign="center"
        css={{ textWrap: 'balance' }}
        opacity="0"
        animation={`${fadeAnimation} 0.5s ease-out forwards 0.3s`}
      >
        Contains 1{' '}
        <Box color="rgba(236, 83, 0, 1)" as="span">
          Blazing
        </Box>{' '}
        booster and 2 extra boosters, either{' '}
        <Box color="rgba(215, 174, 13, 1)" as="span">
          Legendary
        </Box>{' '}
        or{' '}
        <Box color="rgba(143, 40, 173, 1)" as="span">
          Epic
        </Box>
        . Pack is awarded after a 5-day streak. The streak resets if you claim the reward or miss a contest.
      </Text>
    </Flex>
  );
};

export default StreakPromoPage;
